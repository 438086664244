import { Plan } from '~/db/schema'

export const proPlan = {
  id: 'PRO',
  name: '프로',
  description: '프로페셔널들을 위한 요금제입니다. 블로그와 카피를 무제한으로 생생하실 수 있습니다.',
  priceMonthly: 19900,
  priceAnnually: 14900,
  features: ['무제한 카피 생성', '20+ 템플릿 제공'],
  recommended: true,
}

export const freePlan = {
  id: 'FREE',
  name: '무료',
  description: '기본 무료 요금제 입니다.',
  priceMonthly: 0,
  priceAnnually: 0,
  features: ['매달 30 크레딧 제공', '20+ 템플릿 제공'],
  recommended: false,
}

export const plans = [proPlan, freePlan]

export const getOrderName = (plan: Plan) => {
  switch (plan) {
    case Plan.FREE:
      return '무료 플랜'
    case Plan.PLUS_MONTHLY:
      return '타입잇 프로 월간 요금제'
    case Plan.PLUS_ANNUALLY:
      return '타입잇 프로 연간 요금제'
    default:
      throw new Error('Invalid plan')
  }
}
