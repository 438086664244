'use client'

import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { RocketLaunchIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import React from 'react'
import { Button } from '~/components/ui/button'
import { plans } from '~/constants/pricing'
import { Plan } from '~/db/schema'
import { cn } from '~/lib/utils'

const colors = {
  blue: {
    radioGroupOption: 'border-blu-300 bg-white text-gray-700 hover:border-blue-400',
    div: 'bg-blue-900',
  },
  purple: {
    radioGroupOption: 'border-purple-300 bg-white text-gray-700 hover:border-purple-400',
    div: 'bg-purple-600',
  },
  indigo: {
    radioGroupOption: 'border-indigo-300 bg-white text-gray-700 hover:border-indigo-400',
    div: 'bg-indigo-600',
  },
}

const PricingRadioGroup = ({
  isMonthly,
  onChange,
  className,
  color = 'blue',
}: {
  isMonthly: boolean
  onChange: () => void
  color?: 'blue' | 'purple' | 'indigo'
  className?: string
}) => {
  return (
    <div className={clsx('relative', className)}>
      <RadioGroup value={isMonthly} onChange={onChange} className='grid grid-cols-2'>
        {['Annually', 'Monthly'].map((period) => (
          <RadioGroup.Option
            key={period}
            value={period}
            className={clsx(
              period === 'Annually' ? 'rounded-l-lg' : '-ml-px rounded-r-lg',
              colors[color].radioGroupOption,
              'cursor-pointer border px-[calc(theme(spacing.3)-1px)] py-[calc(theme(spacing.2)-1px)] text-center font-semibold text-sm outline-2 outline-offset-2 transition-colors hover:bg-slate-50',
            )}
          >
            {period === 'Monthly' ? (
              '월간 플랜'
            ) : (
              <div className='flex items-center justify-center gap-2'>
                <span>연간 플랜</span>
                <span className='font-light text-xs'>(25% 할인)</span>
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      <div
        aria-hidden='true'
        className={clsx(
          colors[color].div,
          isMonthly ? '[clip-path:inset(0_0_0_calc(50%-1px))]' : '[clip-path:inset(0_50%_0_0)]',
          'pointer-events-none absolute inset-0 z-10 grid grid-cols-2 overflow-hidden rounded-lg transition-all duration-300',
        )}
      >
        {['Annually', 'Monthly'].map((period) => (
          <div
            key={period}
            className={clsx(
              'py-2 text-center font-medium text-sm text-white [&:not(:focus-visible)]:focus:outline-none',
              period === 'Monthly' && '-ml-px',
            )}
          >
            {period === 'Monthly' ? (
              '월간 플랜'
            ) : (
              <div className='flex items-center justify-center gap-2'>
                <span>연간 플랜</span>
                <span className='font-light text-xs'>(25% 할인)</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

const Background = () => (
  <div className='-z-10 absolute inset-x-0 top-20 transform-gpu overflow-hidden px-36 blur-3xl' aria-hidden='true'>
    <svg className='mx-auto w-[72.1875rem]' viewBox='0 0 1155 678' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill='url(#083c110e-60c8-42bb-a9db-29bba74cc569)'
        fillOpacity='.3'
        d='M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z'
      />
      <defs>
        <linearGradient
          id='083c110e-60c8-42bb-a9db-29bba74cc569'
          x1='1155.49'
          x2='-78.208'
          y1='.177'
          y2='474.645'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#9089FC' />
          <stop offset={1} stopColor='#FF80B5' />
        </linearGradient>
      </defs>
    </svg>
  </div>
)

const Heading = () => (
  <div className='mx-auto max-w-2xl text-center lg:max-w-4xl'>
    <h2 className='mt-2 font-bold text-4xl text-gray-900 tracking-tight sm:text-5xl'>간단한 요금제</h2>
  </div>
)

const Paragraph = () => (
  <p className='mx-auto mt-6 max-w-2xl text-center text-gray-600 text-lg leading-8'>
    사용 목적에 맞춰 요금제를 선택해보세요. <br />
    무료 사용자에게도 매달 무료 30 크레딧을 제공해드립니다.
  </p>
)

export function PricingPlan({ href }: { href?: string }) {
  const searchParams = useSearchParams()
  const [selectedPlan, setSelectedPlan] = React.useState<Plan>(Plan.PLUS_ANNUALLY)
  const pathname = usePathname()
  const router = useRouter()

  const handlePeriodChange = () =>
    selectedPlan === Plan.PLUS_MONTHLY ? setSelectedPlan(Plan.PLUS_ANNUALLY) : setSelectedPlan(Plan.PLUS_MONTHLY)

  const onClick = React.useCallback(() => {
    const params = new URLSearchParams(searchParams.toString())
    params.set('plan', selectedPlan)
    params.set('modal', 'purchase')
    router.push(`${pathname}?${params.toString()}`)
  }, [router, searchParams, selectedPlan, pathname])

  return (
    <div className={cn('isolate my-16 px-6 lg:px-8', href === undefined && 'bg-white')}>
      <Background />
      <Heading />
      <Paragraph />
      <div className='mx-auto mt-8 flex max-w-lg items-center justify-center lg:mt-12'>
        <PricingRadioGroup
          isMonthly={selectedPlan === Plan.PLUS_MONTHLY}
          onChange={handlePeriodChange}
          className='w-full'
          color='indigo'
        />
      </div>
      <div className='mx-auto mt-8 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-12 lg:max-w-4xl lg:grid-cols-2 sm:gap-y-0'>
        {plans.map((plan, index) => (
          <div
            key={plan.id}
            className={clsx(
              plan.recommended ? 'relative bg-white shadow-2xl' : 'bg-white/60 lg:mx-0 sm:mx-8',
              plan.recommended
                ? ''
                : index === 0
                  ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl'
                  : 'sm:rounded-t-none lg:rounded-tr-3xl lg:rounded-bl-none',
              'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10',
            )}
          >
            <h3 id={plan.id} className='font-semibold text-base text-indigo-600 leading-7'>
              {plan.name}
            </h3>
            <div className='relative mt-4 flex items-baseline justify-start font-bold text-3xl text-gray-900 tracking-tight'>
              {plan.id === 'FREE' ? (
                <span className='font-bold text-5xl text-gray-900 tracking-tight'>
                  {plan.priceAnnually.toLocaleString()}
                </span>
              ) : (
                <div className='font-bold text-5xl text-gray-900 tracking-tight'>
                  <span
                    aria-hidden={selectedPlan === Plan.PLUS_MONTHLY}
                    className={clsx(
                      'transition duration-300',
                      selectedPlan === Plan.PLUS_ANNUALLY && 'pointer-events-none translate-x-6 select-none opacity-0',
                    )}
                  >
                    {plan.priceMonthly.toLocaleString()}
                  </span>
                  <span
                    aria-hidden={selectedPlan === Plan.PLUS_ANNUALLY}
                    className={clsx(
                      'absolute top-0 left-0 transition duration-300',
                      selectedPlan === Plan.PLUS_MONTHLY && '-translate-x-6 pointer-events-none select-none opacity-0',
                    )}
                  >
                    {plan.priceAnnually.toLocaleString()}
                  </span>
                </div>
              )}
              <span className='pl-2 text-gray-500 text-lg'>/ 월</span>
            </div>
            <p className='mt-6 text-base text-gray-600 leading-7'>{plan.description}</p>
            <ul className='mt-8 space-y-3 text-gray-600 text-sm leading-6 sm:mt-10'>
              {plan.features.map((feature) => (
                <li key={feature} className='flex gap-x-3'>
                  <CheckIcon className='h-6 w-5 flex-none text-indigo-600' aria-hidden='true' />
                  {feature}
                </li>
              ))}
            </ul>
            {plan.recommended ? (
              <Button
                aria-describedby={plan.id}
                color={plan.recommended ? 'indigo' : 'light'}
                className='mt-6 flex w-full text-center'
                onClick={onClick}
                disabled={href !== undefined ? false : !plan.recommended}
                {...(href && { href: href })}
              >
                <RocketLaunchIcon className='h-6 w-6' aria-hidden='true' />
                {href !== undefined ? '무료로 시작하기' : plan.recommended ? '업그레이드' : '현재 요금제'}
              </Button>
            ) : (
              <Button
                type='button'
                outline
                aria-describedby={plan.id}
                className='mt-6 block w-full text-center'
                disabled={href !== undefined ? false : !plan.recommended}
                {...(href && { href: href })}
              >
                {href !== undefined ? '무료로 시작하기' : plan.recommended ? '업그레이드' : '현재 요금제'}
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
