import type { AdapterAccount } from '@auth/core/adapters'
import { createId } from '@paralleldrive/cuid2'
import { sql } from 'drizzle-orm'
import {
  boolean,
  index,
  integer,
  json,
  pgEnum,
  pgTable,
  primaryKey,
  text,
  timestamp,
  uniqueIndex,
} from 'drizzle-orm/pg-core'

export const userTable = pgTable(
  'users',
  {
    id: text('id')
      .$defaultFn(() => createId())
      .primaryKey(),
    name: text('name'),
    credit: integer('credit').default(30).notNull(),
    email: text('email').notNull().unique(),
    image: text('image'),
    emailVerified: timestamp('email_verified', { mode: 'date' }),
    favoriteTemplates: text('favorite_templates').array().default(sql`'{}'`).notNull(),
    subscribed: boolean('subscribed').default(false),

    createdAt: timestamp('created_at').defaultNow().notNull(),
    updatedAt: timestamp('updated_at').defaultNow().notNull(),
  },
  (t) => {
    return {
      emailIdx: index('email_user_idx').on(t.email),
    }
  },
)

export const accountTable = pgTable(
  'accounts',
  {
    userId: text('user_id')
      .notNull()
      .references(() => userTable.id, { onDelete: 'cascade' }),
    type: text('type').$type<AdapterAccount['type']>().notNull(),
    provider: text('provider').notNull(),
    providerAccountId: text('provider_account_id').notNull(),
    refresh_token: text('refresh_token'),
    access_token: text('access_token'),
    expires_at: integer('expires_at'),
    token_type: text('token_type'),
    scope: text('scope'),
    id_token: text('id_token'),
    session_state: text('session_state'),
  },
  (t) => ({
    compoundKey: primaryKey({ columns: [t.provider, t.providerAccountId] }),
  }),
)

export enum Plan {
  FREE = 'FREE',
  PLUS_MONTHLY = 'PLUS_MONTHLY',
  PLUS_ANNUALLY = 'PLUS_ANNUALLY',
}
export const planEnum = pgEnum('plan', [Plan.FREE, Plan.PLUS_MONTHLY, Plan.PLUS_ANNUALLY])

export const subscriptionTable = pgTable(
  'subscription',
  {
    id: text('id')
      .$defaultFn(() => createId())
      .primaryKey(),
    plan: planEnum('plan').notNull(),
    anchorDate: timestamp('anchor_date').notNull(),
    lastBillingDate: timestamp('last_billing_date').notNull(),
    nextBillingDate: timestamp('next_billing_date').notNull(),
    cancelled: boolean('cancelled').default(false).notNull(),
    cancelledDate: timestamp('cancelled_date'),
    cancelledReason: text('cancelled_reason'),

    userId: text('user_id')
      .references(() => userTable.id, { onDelete: 'cascade' })
      .unique()
      .notNull(),
  },
  (t) => {
    return {
      userIdSubscriptionIdx: uniqueIndex('user_id_subscription_idx').on(t.userId),
    }
  },
)

export const copyTable = pgTable(
  'copies',
  {
    id: text('id')
      .$defaultFn(() => createId())
      .primaryKey(),
    prompts: text('prompts').array().default(sql`'{}'`).notNull(),
    content: text('content').notNull(),
    editedContent: text('edited_content'),
    templateId: text('template_id').notNull(),
    inputTokenCount: integer('input_token_count').default(0),
    outputTokenCount: integer('output_token_count').default(0),
    removed: boolean('removed').default(false).notNull(),
    liked: boolean('liked').default(false).notNull(),
    shared: boolean('shared').default(false).notNull(),

    createdAt: timestamp('created_at').defaultNow().notNull(),
    updatedAt: timestamp('updated_at').defaultNow().notNull(),

    userId: text('user_id')
      .references(() => userTable.id, { onDelete: 'no action' })
      .notNull(),
  },
  (t) => {
    return {
      userIdCopyIdx: index('user_id_copy_idx').on(t.userId),
    }
  },
)

// export const instaTable = pgTable(
//   'instagram',
//   {
//     id: text('id')
//       .$defaultFn(() => createId())
//       .primaryKey(),
//     caption: text('caption').notNull(),
//     hashtag: text('hashtag').notNull(),
//     imageUrl: text('image_url').notNull(),
//     liked: boolean('liked').default(false).notNull(),
//     removed: boolean('removed').default(false).notNull(),
//
//     createdAt: timestamp('created_at').defaultNow().notNull(),
//     updatedAt: timestamp('updated_at').defaultNow().notNull(),
//
//     userId: text('user_id')
//       .references(() => userTable.id)
//       .notNull(),
//   },
//   (table) => {
//     return {
//       userIdInstaIdx: uniqueIndex('user_id_insta_idx').on(table.userId),
//     }
//   },
// )

export const cardTable = pgTable(
  'cards',
  {
    id: text('id')
      .$defaultFn(() => createId())
      .primaryKey(),
    billingKey: text('billing_key').notNull(),
    authenticatedAt: timestamp('authenticated_at').notNull(),
    method: text('method').notNull(),
    cardNumber: text('card_number').notNull(),
    cardIssuerCode: text('card_issuer_code').notNull(),
    cardAcquirerCode: text('card_acquirer_code').notNull(),
    cardType: text('card_type').notNull(),
    cardOwnerType: text('card_owner_type').notNull(),

    createdAt: timestamp('created_at').defaultNow().notNull(),
    updatedAt: timestamp('updated_at').defaultNow().notNull(),

    userId: text('user_id')
      .references(() => userTable.id, { onDelete: 'cascade' })
      .notNull(),
  },
  (t) => {
    return {
      userIdCardIdx: uniqueIndex('user_id_card_idx').on(t.userId),
    }
  },
)

export const invoiceTable = pgTable(
  'invoices',
  {
    id: text('id')
      .$defaultFn(() => createId())
      .primaryKey(),
    mId: text('m_id').notNull(),
    orderId: text('order_id').notNull(),
    orderName: text('order_name').notNull(),
    paymentKey: text('payment_key').notNull(),

    receiptUrl: text('receipt_url'),
    checkoutUrl: text('checkout_url'),

    // amount
    totalAmount: integer('total_amount').notNull(),
    balanceAmount: integer('balance_amount').notNull(),
    suppliedAmount: integer('supplied_amount').notNull(),
    vat: integer('vat').notNull(),
    taxFreeAmount: integer('tax_free_amount').notNull(),
    method: text('method').notNull(),
    version: text('version').notNull(),

    // rest of data
    data: json('data').notNull(),

    createdAt: timestamp('created_at').defaultNow().notNull(),
    updatedAt: timestamp('updated_at').defaultNow().notNull(),

    userId: text('user_id')
      .references(() => userTable.id, { onDelete: 'no action' })
      .notNull(),
  },
  (t) => {
    return {
      userIdInvoiceIdx: index('user_id_invoice_idx').on(t.userId),
    }
  },
)

export const emailMarketingSettingsTable = pgTable(
  'email_marketing_setting',
  {
    id: text('id')
      .$defaultFn(() => createId())
      .primaryKey(),
    update: boolean('update').default(true).notNull(),
    newsletter: boolean('newsletter').default(true).notNull(),
    billing: boolean('billing').default(true).notNull(),

    createdAt: timestamp('created_at').defaultNow().notNull(),
    updatedAt: timestamp('updated_at').defaultNow().notNull(),

    userId: text('user_id')
      .references(() => userTable.id, { onDelete: 'cascade' })
      .notNull(),
  },
  (t) => {
    return {
      userIdEmailMarketingSettingsIdx: uniqueIndex('user_id_email_marketing_settings_idx').on(t.userId),
    }
  },
)

export const DocumentTable = pgTable(
  'document',
  {
    id: text('id')
      .$defaultFn(() => createId())
      .primaryKey(),
    title: text('title').notNull(),
    content: json('content'),
    preview: text('preview'),

    createdAt: timestamp('created_at').defaultNow().notNull(),
    updatedAt: timestamp('updated_at').defaultNow().notNull(),

    userId: text('user_id')
      .references(() => userTable.id, { onDelete: 'cascade' })
      .notNull(),
  },
  (table) => {
    return {
      userIdDocumentIdx: index('user_id_document_idx').on(table.userId),
    }
  },
)
